import React from "react";
import { Link } from "gatsby";

export default class ConnectedNarratives extends React.Component {
  constructor(props) {
    super(props);

    this.narratives = this.props.narratives.map(({ node }) => {
      const exhibit = this.props.exhibits.filter(edge => {
          return edge.node.reference === node.exhibit;
        })[0];

      const imageReference = node.featuredImage;
      const featuredImageNodes = this.props.images.filter(({ node }) => {
        return node.reference === imageReference;
      });

      const featuredImage = (featuredImageNodes.length > 0) ?
        (<img src={`/images/content/${featuredImageNodes[0].node.thumbPath}`}
              alt={featuredImageNodes[0].node.alt} />) : null;

      return {
        narrativeReference: node.reference,
        narrativeTitle: node.title,
        narrativeBlurb: node.blurb,
        exhibitReference: exhibit.node.reference,
        exhibitTitle: exhibit.node.long_title,
        featuredImage: featuredImage
      };
    });
  }

  render() {
    return (
      <ul className="connected-narratives">
        {this.narratives.map((narrative, idx) => (
          <li key={idx}>
            <Link to={`/${narrative.exhibitReference}/narratives/${narrative.narrativeReference}`} className="connected-narratives__headline-link">
              <span className="narrative">
                {narrative.featuredImage}
                {narrative.narrativeTitle}
              </span>
              <span className="exhibit">in “{narrative.exhibitTitle}”</span>
            </Link>
            <p>
              {narrative.narrativeBlurb}
              <Link to={`/${narrative.exhibitReference}/narratives/${narrative.narrativeReference}`}>Read more…</Link> 
            </p>
          </li>
        ))}
      </ul>
    );
  }
}