import React from "react";

import { Map, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import iconShadow from "leaflet/dist/images/marker-shadow.png";

export default class SingleVenueMap extends React.Component {
  constructor(props) {
    super(props);

    let lat, lng;

    if (this.props.locations.length > 1) {
      let lattitudes = this.props.locations.map(location => { return location.coordinates.lat });
      let longitudes = this.props.locations.map(location => { return location.coordinates.lon });

      const minLat = Math.min(...lattitudes);
      const maxLat = Math.max(...lattitudes);
      const minLng = Math.min(...longitudes);
      const maxLng = Math.max(...longitudes);

      lat = (minLat + maxLat) / 2;
      lng = (minLng + maxLng) / 2;
    } else if (this.props.locations.length === 1) {
      lat = this.props.locations[0].coordinates.lat;
      lng = this.props.locations[0].coordinates.lon;
    }

    this.state = {
      lat: lat,
      lng: lng,
      zoom: 15,
    };
  }

  componentDidMount() {
    let L = require('leaflet');

    let DefaultIcon = L.icon({
      iconUrl: "/images/interface/maps/marker.png",
      iconRetinaUrl: "/images/interface/maps/marker-2x.png",
      iconSize: [25,43],
      iconAnchor: [12.5, 43],
      popupAnchor: [1, -24],
      shadowUrl: iconShadow,
    });

    L.Marker.prototype.options.icon = DefaultIcon;
  }

  render() {
    const position = [this.state.lat, this.state.lng]

    if (typeof window !== 'undefined') {
      return (
        <Map className="map-wrapper" 
          center={position}
          zoom={this.state.zoom}
          maxBounds={[[33.3, -99],[29.2, -94.9]]}
          minZoom={9}
          maxZoom={18}
          scrollWheelZoom={false}
         >
          <TileLayer
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxZoom={18}
            attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          />
          {this.props.locations.map(location => (
            <Marker position={location.coordinates} />
          ))}
        </Map>
      );
    }

    return null;
  }
}