import React from "react";

import ListingGridItem from "../../components/browser/listing-grid-item";

export default (props) => {
  const views = (props.views) ? props.views : null;

  if (views) {
    return (
      <div>
        <h2>{props.label}</h2>
        <ul className="listing-grid">
          {views.map(({ node }, idx) => {
            let label = '';

            if (node.canonical_name) {
              // Artists and venues
              label = node.canonical_name;
            } else if (node.title) {
              // Narratives
              label = node.title;
            }

            return (
            <ListingGridItem
              label={label}
              target={`${props.path}${node.reference}`}
              key={idx} />)
          })}
        </ul>
      </div>
    );
  }

  return null;
}