import React from "react";

export default class VenueLocationDescription extends React.Component {
  constructor(props) {
    super(props);

    this.description = this.description.bind(this);
  }

  description() {
    if (this.props.location.type === 'precise') {
      return this.props.location.address;
    } else {
      return `“${this.props.location.description}”`;
    }
  }

  render() {
    return (
      <p className="long-display-block">
        <b>{this.description()}</b><br />
        {this.props.venue.city}, {this.props.venue.state}
      </p>
    );
  }
}