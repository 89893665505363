import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout/layout";

import Helmet from "react-helmet";

import PageContainer from "../components/layout/page-container";
import PageSection from "../components/layout/page-section";

import RelatedViewsBlock from "../components/browser/related-views";
import ConnectedNarrativesBlock from "../components/browser/connected-narratives";
import ConnectedVenuesBlock from "../components/browser/connected-venues";

import VenueFeaturedImage from "../components/venues/featured-image";
import VenueLocationDescription from "../components/venues/location-description";
import SingleVenueMap from "../components/maps/single-venue-map";

class Venue extends React.Component {
  constructor({ data }) {
    super();
    

    this.data = data;
    this.featuredImage = this.getFeaturedImage();

    this.getFeaturedImage = this.getFeaturedImage.bind(this);

    this.getUniqueImageYears = this.getUniqueImageYears.bind(this);
    this.countUniqueImageYears = this.countUniqueImageYears.bind(this);
  }

  hasPreciseLocations(locations) {
    if (locations && locations.length > 0) {
      let precision = false;
      locations.forEach(location => {
        precision = precision || (location.type === 'precise');
      });

      return precision;
    }

    return false;
  }

  getFeaturedImage() {
    const data = this.data;

    if (data.featuredImage) {
      const filteredImages = data.venueImages.edges.filter((image) => {
        return image.node.reference === data.featuredImage.image_reference
      });

      if (filteredImages.length > 0) {
        return filteredImages[0].node;
      }
    }

    return null;
  }

  performancesLink() {
    const edgesCount = this.data.venuePerformances.edges.length;
    const linkLabel = `${edgesCount} ${(edgesCount === 1) ? 'performance' : 'performances'}`;

    return (<Link to={`/performances/venue/${this.data.venue.reference}`}>{linkLabel}</Link>);
  }

  imagesLink() {
    const edgesCount = this.data.venueImages.edges.length;
    const linkLabel = `${edgesCount} ${(edgesCount === 1) ? 'image' : 'images'}`;

    return (<Link to={`/images/venue/${this.data.venue.reference}`}>{linkLabel}</Link>);
  }

  performanceYearsLinks() {
    const allYears = this.data.venuePerformances.edges.reduce((result, { node }) => {
      node.dates.forEach(date => result.push(date.substr(0, 4)));

      return result;
    }, []);

    const uniqueYears = allYears.filter((year, idx, self) => {
      return (year !== null) && (self.indexOf(year) === idx);
    });

    if (uniqueYears.length === 1) {
      return (<Link to={`/performances/years/${uniqueYears[0]}`}>{uniqueYears[0]}</Link>);
    }

    if (uniqueYears.length === 2) {
      return(
        <span>
          <Link to={`/performances/years/${uniqueYears[0]}`}>{uniqueYears[0]}</Link>
          <span> and </span>
          <Link to={`/performances/years/${uniqueYears[1]}`}>{uniqueYears[1]}</Link>
        </span>
      );
    }

    return (
      <span>
        {uniqueYears.map((year, idx, self) => (
          <span key={idx}>
            {(idx < self.length - 1) ?
              (<span><Link to={`/performances/years/${year}`}>{year}</Link>, </span>)
              :
              (<span>and <Link to={`/performances/years/${year}`}>{year}</Link></span>)
            }
          </span>
        ))}
      </span>
    )
  }

  getUniqueImageYears() {
    const allYears = this.data.venueImages.edges.map(({ node }) => node.year);

    const uniqueYears = allYears.filter((year, idx, self) => {
      return (year !== null) && (self.indexOf(year) === idx);
    });

    return uniqueYears;
  }

  countUniqueImageYears() {
    const uniqueYears = this.getUniqueImageYears();

    return uniqueYears.length;
  }

  imageYearsLinks() {
    const uniqueYears = this.getUniqueImageYears();

    if (uniqueYears.length === 1) {
      return (<Link to={`/images/years/${uniqueYears[0]}`}>{uniqueYears[0]}</Link>);
    }

    if (uniqueYears.length === 2) {
      return (
        <span>
          <Link to={`/images/years/${uniqueYears[0]}`}>{uniqueYears[0]}</Link>
          <span> and </span>
          <Link to={`/images/years/${uniqueYears[1]}`}>{uniqueYears[1]}</Link>
        </span>
      );
    }

    return (
      <span>
        {uniqueYears.map((year, idx, self) => (
          <span>
            {(idx < self.length - 1) ?
              (<span><Link to={`/images/years/${year}`}>{year}</Link>, </span>)
              :
              (<span>and <Link to={`/images/years/${year}`}>{year}</Link></span>)
            }
          </span>
        ))}
      </span>
    );
  }

  render() {
    const data = this.data;

    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <Helmet>
            <title>{`${data.venue.canonical_name} – Venue`}</title>
          </Helmet>

          <PageSection background="dark-accent">
            <h1 className="page-title">
              {data.venue.canonical_name}
            </h1>
          </PageSection>

          {(this.featuredImage) ?
            <VenueFeaturedImage image={this.featuredImage} venue={data.venue} />
            : null
          }

          {(data.venuePerformances && data.venuePerformances.edges.length > 0) ? (
            <PageSection background="gray" topPadding="sm" bottomPadding="sm">
              <p className="long-display-block">
                <b>{data.venue.canonical_name}</b> appears in {this.performancesLink()} from {this.performanceYearsLinks()}.
              </p>
            </PageSection>
          ) : null}

          {(data.venueImages && data.venueImages.edges.length > 0) ? (
            <PageSection background="gray" wrapper="wrapper" bottomPadding="md">
              <p className="long-display-block">
                There {(data.venueImages.edges.length === 1) ? (<span>is</span>) : (<span>are</span>)} {this.imagesLink()} of <b>{data.venue.canonical_name}</b>
                {(this.countUniqueImageYears() > 0) ? (<span> from {this.imageYearsLinks()}</span>) : null}
                .
              </p>
            </PageSection>
          ) : null}

            {(data.venue.locations && data.venue.locations.length > 0) ? (
              <div>
                {(this.hasPreciseLocations(data.venue.locations)) ? (
                  <PageSection
                    topPadding="md"
                    background="gray"
                  >
                    <SingleVenueMap locations={data.venue.locations} />
                  </PageSection>
                ) : null}
                <PageSection
                  topPadding="md"
                  bottomPadding="md"
                  background="gray"
                >
                  {data.venue.locations.map(location => (
                    <VenueLocationDescription 
                      venue={data.venue} 
                      location={location} />
                  ))}
                </PageSection>
              </div>
            ) : null}

          <PageSection
            topPadding="md"
          >
            {(data.connectedNarratives) ? (
              <div className="entity-page__block">
                <h2>Read more about {data.venue.canonical_name} in…</h2>
                <ConnectedNarrativesBlock 
                    exhibits={data.allExhibitsJson.edges}
                    images={data.allImagesJson.edges}
                    narratives={data.connectedNarratives.edges} />
              </div>
            ) : null}

            {(data.relatedVenues) ? (
              <div className="entity-page__block">
                <RelatedViewsBlock views={data.relatedVenues.edges}
                                  path="/venues/"
                                  label="Related Venues" />
              </div>
            ) : null}

            {(data.parentVenue || data.childVenues) ? (
              <div className="entity-page__block">
                <h2>Connected Venues</h2>
                <ConnectedVenuesBlock venue={data.venue}
                                      parentVenue={data.parentVenue}
                                      childVenues={data.childVenues} 
                                      allVenues={data.allVenuesJson} />
              </div>
            ) : null}

          </PageSection>

          <PageSection
            topPadding="lg"
            bottomPadding="md"
          >
            <div className="big-button big-button__venues">
              <Link to="/venues">
                <span className="label">Browse Venues</span>
              </Link>
            </div>  
          </PageSection>
        </PageContainer>
      </Layout>
    );
  }
}

export default Venue;

export const query = graphql`
  query VenueQuery($slug: String!) {
    venuePerformances: allPerformancesJson(
      filter: {venue: {eq: $slug}, reviewed: {eq: true}},
      sort: {fields: [dates], order: ASC}) {
      edges {
        node {
          dates
          artists
          venue
        }
      }
    },
    venueImages: allImagesJson(
      filter: {venues: {eq: $slug}}) {
      edges {
        node {
          reference
          fullPath
          thumbPath
          alt
          caption
          credit
          narratives
          artists
          venues
          year
        }
      }
    },
    featuredImage: featuredVenueImagesJson(venue_reference: {eq: $slug}) {
      image_reference
    },
    venue: venuesJson(reference: { eq: $slug }) {
      reference
      canonical_name
      city
      state
      locations {
        type
        address
        description
        coordinates {
          lat
          lon
        }
      }
      related
    },
    relatedVenues: allVenuesJson(filter: {related: {eq: $slug}}) {
      edges {
        node {
          reference
          canonical_name
        }
      }
    },
    parentVenue: venuesJson(child_references: {eq: $slug}) {
      reference
      canonical_name
    },
    childVenues: allVenuesJson(filter: {parent_reference: {eq: $slug}}) {
      edges {
        node {
          reference
          canonical_name
        }
      }
    },
    connectedNarratives: allNarrativesJson(filter: {venues: {eq: $slug}}) {
      edges {
        node {
          featuredImage
          exhibit
          reference
          title
          blurb
        }
      }
    },
    allExhibitsJson {
      edges {
        node {
          reference
          long_title
        }
      }
    },
    allImagesJson {
      edges {
        node {
          reference
          alt
          thumbPath
        }
      }
    },
    allNarrativesJson {
      edges {
        node {
          reference
          title
        }
      }
    },
    allArtistsJson {
      edges {
        node {
          reference
          canonical_name
        }
      }
    },
    allVenuesJson {
      edges {
        node {
          reference
          parent_reference
          canonical_name
        }
      }
    }
  }
`;
