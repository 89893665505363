import React from "react";

import PageSection from "../layout/page-section";
import { markdownFormatWithoutPTags } from "../helpers";

export default (props) => {
  return (
    <PageSection
      wrapperType="wrapper"
      background="dark-gray"
    >
      <div className="featured-image-header">
        <img src={`/images/content/${props.image.fullPath}`} alt={props.image.alt} />

        <div className="bottom-bar">
          <div className="caption-block">
            <p>
              {(props.image.caption) ? 
                (<span className="caption"
                       dangerouslySetInnerHTML={{
                        __html: markdownFormatWithoutPTags(props.image.caption)}} />)
                : null}
              {(props.image.credit) ? 
                (<span className="credit"
                       dangerouslySetInnerHTML={{ 
                        __html: markdownFormatWithoutPTags(props.image.credit)}} />) 
                : null}
            </p>
          </div>
        </div>
      </div>
    </PageSection>
  )
}