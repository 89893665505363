import React from "react";
import { Link } from "gatsby";

export default (props) => {
  let topVenue, bottomVenues;

  if (props.parentVenue) {
    topVenue = props.parentVenue;
    bottomVenues = props.allVenues.edges.filter(edge => {
      return (edge.node.parent_reference === topVenue.reference);
    });
  } else {
    topVenue = props.venue;
    bottomVenues = props.childVenues.edges;
  }

  return (
    <div className="connected-venues">
      <div className="connected-venues__top">
        {(props.venue.reference === topVenue.reference)
        ?
          (<span className="here">{topVenue.canonical_name}</span>)
        :
          (
            <Link to={`/venues/${topVenue.reference}`}>
              {topVenue.canonical_name}
            </Link>
          )}
      </div>
      <div className="connected-venues__bottom">
        <ul>
          {bottomVenues.map(venue => (
            <li>
              {(props.venue.reference === venue.node.reference)
              ? 
                (<span className="here">{venue.node.canonical_name}</span>)
              :
                (
                  <Link to={`/venues/${venue.node.reference}`}>
                    <span className="color-tag">
                      {venue.node.canonical_name}
                    </span>
                  </Link>
                )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}