import React from "react";
import { Link } from "gatsby";

export default (props) => (
  <li className="listing-grid__cell">
    <Link to={props.target}>
      <span className="listing-grid__cell__label">
        {props.label}
      </span>
    </Link>
  </li>
);